import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Event, EventAction, EventActionList, EventList, MediaFile, MediaFileList } from './event.model';
import { Pagination } from 'app/core/api/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private _events: BehaviorSubject<Event[] | null> = new BehaviorSubject(null);
    private _event: BehaviorSubject<Event | null> = new BehaviorSubject(null);
    private _eventMedia: BehaviorSubject<MediaFile[] | null> = new BehaviorSubject(null);
    private _eventActions: BehaviorSubject<EventAction[] | null> = new BehaviorSubject(null);
    errorMessage: string = "";
    pagination: Pagination;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for events
     */
    get events$(): Observable<Event[]> {
        return this._events.asObservable();
    }

    get event$(): Observable<Event> {
        return this._event.asObservable();
    }

    updatedSelectedEvent(event: Event) {
        this._event.next(event)
    }

    get eventMedias$(): Observable<MediaFile[]> {
        return this._eventMedia.asObservable();
    }

    get eventActions$(): Observable<EventAction[]> {
        return this._eventActions.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get Event list 
     */

    getEventsInitial(categoryId = null): Observable<EventList> {
        return this.getEvents(null, null, null, null, null, categoryId, null, null, null, null, null)
    }

    getEvents(page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, categoryId: number, categoryTypeId: number, projectId: number, status: string, factoryId: number, regionId: number): Observable<EventList> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            ordering: sort ? sort : '',
            search: query ? query : '',
            page_size: batch_size ? batch_size : 10
        }
        if (status != null && status != 'all')
            params['status'] = status
        if (factoryId != null && factoryId > 0)
            params['factory'] = factoryId
        if (regionId != null && regionId > 0)
            params['region'] = regionId
        if (projectId != null && projectId > 0)
            params['project'] = projectId
        if (categoryId != null && categoryId > 0)
            params['category'] = categoryId
        if (categoryTypeId != null && categoryTypeId > 0)
            params['category_type'] = categoryTypeId
        return this._httpClient.get<EventList>(`${BASE_URL}event/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                if (response.data?.length == 0)
                    this.errorMessage = "There are no items to display!"
                this._events.next(response.data);
            })
        );
    }

    getEventDetail(eventId): Observable<Event> {
        return this._httpClient.get<Event>(`${BASE_URL}event/${eventId}`).pipe(
            tap((response) => {
                this._event.next(response);
            })
        );
    }

    getEventDetailsForGuest(eventId): Observable<Event> {
        return this._httpClient.get<Event>(`${BASE_URL}event/access/${eventId}`).pipe(
            tap((response) => {
                this._event.next(response);
            })
        );
    }

    deleteEvent(eventId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}event/${eventId}/`).pipe(
            tap((res: any) => {
                this.pagination.total_count -= 1
                this._events.next(this._events.value.filter(Event => Event.id !== eventId));
            }, err => of([]))
        )
    }

    createEvent(category, credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}event/?category=${category}`, credentials);
    }

    updateEvent(credentials, eventId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}event/${eventId}/`, credentials);
    }

    ///Media files
    getMediaFilesInitial(eventId: string): Observable<MediaFileList> {
        return this.getMediaFiles(eventId, false, null)
    }
    mediaPage = 1
    private _hasMore: BehaviorSubject<Boolean> = new BehaviorSubject(null);

    get hasMoreItems$(): Observable<Boolean> {
        return this._hasMore.asObservable();
    }
    getMediaFiles(eventId: string, nextPage: Boolean = false, query: string): Observable<MediaFileList> {
        if (nextPage)
            this.mediaPage++
        else
            this.mediaPage = 1
        let params = {
            page: this.mediaPage,
            page_size: 50
        }
        if (query != null)
            params['search'] = query
        return this._httpClient.get<MediaFileList>(`${BASE_URL}event/${eventId}/media/`, {
            params: params
        }).pipe(
            tap((response) => {
                response.data.forEach(doc => {
                    doc.name = this.getFileName(doc.file)
                    doc.extension = this.getFileExtension(doc.name)
                })
                this.pagination = {
                    page: --response.page,
                    total_count: response.total_count
                };
                this._eventMedia.next(response.data);

                if (nextPage) {
                    let tempMedia: MediaFile[] = this._eventMedia.value
                    tempMedia = [...tempMedia, ...response.data]
                    this._eventMedia.next(tempMedia);
                } else {
                    this._eventMedia.next(response.data);
                }
                this._hasMore.next(response.page < response.total_pages)
            })
        );
    }
    getMediaFilesForGuest(eventId: string, nextPage: Boolean = false, query: string): Observable<MediaFile[]> {
        if (nextPage)
            this.mediaPage++
        else
            this.mediaPage = 1
        let params = {
            page: this.mediaPage,
            page_size: 50
        }
        if (query != null)
            params['search'] = query
        return this._httpClient.get<MediaFile[]>(`${BASE_URL}event/access/${eventId}/media/`, {
            params: params
        }).pipe(
            tap((response) => {
                response?.forEach(doc => {
                    doc.name = this.getFileName(doc.file)
                    doc.extension = this.getFileExtension(doc.name)
                })
                this._eventMedia.next(response);
            })
        );
    }

    getFileExtension(name) {
        return (name.split('.')[1].toUpperCase())
    }

    getFileName(url) {
        return url.split('/').pop().split('#')[0].split('?')[0]
    }


    deleteMediaFile(eventId: number, mediaFileId: number): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}event/${eventId}/media/${mediaFileId}/`).pipe(
            tap((res: any) => {
                this._eventMedia.next(this._eventMedia.value.filter(mediaFile => mediaFile.id !== mediaFileId));
            }, err => of([]))
        )
    }

    createMediaFile(eventId: number, credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}event/${eventId}/media/`, credentials);
    }

    updateMediaFile(eventId: number, mediaId: number, credentials): Observable<any> {
        return this._httpClient.put(`${BASE_URL}event/${eventId}/media/${mediaId}/`, credentials);
    }

    closeEvent(eventId: number, credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}event/${eventId}/action/`, credentials);
    }

    approveEvent(eventId: number, credentials): Observable<any> {
        return this._httpClient.post(`${BASE_URL}event/${eventId}/action/`, credentials);
    }

    private _messages: BehaviorSubject<EventAction[]> = new BehaviorSubject(null);
    private _hasMoreMessages: BehaviorSubject<Boolean> = new BehaviorSubject(null);
    get messages$(): Observable<EventAction[]> {
        return this._messages.asObservable();
    }
    get hasMoreMessages$(): Observable<Boolean> {
        return this._hasMoreMessages.asObservable();
    }

    messagesPage = 1
    getEventHistory(id: string, nextPage: Boolean = false): Observable<any> {
        if (nextPage)
            this.messagesPage++
        else
            this.messagesPage = 1
        return this._httpClient.get<EventActionList>(`${BASE_URL}event/${id}/action/?batch=50&page=${this.messagesPage}`, { params: { id } }).pipe(
            map((chat) => {
                chat?.data?.forEach(element => {
                    element.from_admin = element?.is_approved || element?.is_rejected
                });
                // Update the chat
                if (nextPage) {
                    let tempMessages: EventAction[] = this._messages.value
                    tempMessages = [...tempMessages, ...chat.data]
                    this._messages.next(tempMessages);
                } else {
                    this._messages.next(chat.data);
                }
                this._hasMoreMessages.next(chat.page < chat.total_pages)
                // Return the chat
                return chat;
            }),
            switchMap((chat) => {
                if (!chat) {
                    return throwError('Could not found chat with id of ' + id + '!');
                }
                return of(chat);
            })
        );
    }
    

    getEventHistoryGuest(id: string): Observable<any> {
        return this._httpClient.get<EventAction[]>(`${BASE_URL}event/access/${id}/action/`, { params: { id } }).pipe(
            map((chat) => {
                chat?.forEach(element => {
                    element.from_admin = element?.is_approved || element?.is_rejected
                });
                this._messages.next(chat);
                // Return the chat
                return chat;
            }),
            switchMap((chat) => {
                if (!chat) {
                    return throwError('Could not found chat with id of ' + id + '!');
                }
                return of(chat);
            })
        );
    }

    getSingleAccessToken(eventID): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}event/access/`, {event_id : eventID});
    }

    generateExportRequest(eventId): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}event/${eventId}/pdf/`, {});
    }
}
